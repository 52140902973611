var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('div',{staticClass:"report-box"},[_c('div',{staticClass:"report"},[_c('div',{staticClass:"report-title"},[_vm._m(0),_c('div',{staticClass:"title-r"},[_c('span',{staticClass:"back",on:{"click":_vm.gotoXuexi}},[_vm._v("返回")])])]),_c('div',{staticClass:"report-container"},[_c('div',{staticClass:"report-info"},[_c('div',{staticClass:"info-choose"},[_c('span',{class:['info-r', _vm.tab_index == 0 ? 'reCheck' : ''],on:{"click":function($event){return _vm.clkTab(0)}}},[_vm._v("测试报告")])]),(_vm.tab_index == 0)?_c('div',{staticClass:"info-content"},[_c('div',{staticClass:"content-box"},[_vm._m(1),_c('div',{staticClass:"box-table"},[_c('el-table',{staticStyle:{"width":"100%"},attrs:{"border":"","max-height":"800px","header-cell-style":{
                  textAlign: 'center',
                  background: '#fdf6ea',
                  paddingTop: '0',
                },"cell-style":{
                  fontSize: '12px',
                  textAlign: 'center',
                  fontWeight: '400',
                  cursor: 'pointer',
                },"data":_vm.tableData}},[_c('el-table-column',{attrs:{"label":"序号","width":"50","prop":"id"}}),_c('el-table-column',{attrs:{"prop":"shijuan_set.title","label":"考试名称"}}),_c('el-table-column',{attrs:{"prop":"shijuan.title","label":"试卷名称"}}),_c('el-table-column',{attrs:{"prop":"created_at","label":"测试时间","width":"160"}}),_c('el-table-column',{attrs:{"prop":"yongshi_time","label":"测试耗时","width":"100"}}),_c('el-table-column',{attrs:{"prop":"score","label":"分数","width":"100"}})],1),_c('div',{staticClass:"footer-page"},[_c('div',{staticClass:"p-info"},[_vm._v(" 第"),_c('span',{staticClass:"p-num"},[_vm._v(_vm._s(_vm.page))]),_vm._v("/ "),_c('span',{staticClass:"p-total"},[_vm._v(_vm._s(_vm.allPage))]),_vm._v("页，共 "),_c('span',{staticClass:"p-data"},[_vm._v(_vm._s(_vm.total))]),_vm._v("条数据 ")]),_c('el-pagination',{attrs:{"background":"","layout":"prev, pager, next","total":_vm.total,"hide-on-single-page":false,"page-size":_vm.pageSize,"current-page":_vm.page},on:{"current-change":_vm.handleCurrentChange}})],1)],1)])]):_vm._e()])])])])])}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"title-l"},[_c('span',{staticClass:"short"},[_vm._v("心铭远单词速记")]),_vm._v(" > "),_c('span',{staticClass:"inform"},[_vm._v("总体报告")])])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"box-tips"},[_c('span',[_vm._v("学习报告展示了你测试的内容、测试时间以及测试成绩等数据。")])])}]

export { render, staticRenderFns }