<template>
    <div>
        <div class="report-box">
            <div class="report">
                <div class="report-title">
                    <div class="title-l">
                        <span class="short">心铭远单词速记</span>
                        >
                        <span class="inform">总体报告</span>
                    </div>
                    <div class="title-r">
                        <span class="back" @click="gotoXuexi">返回</span>
                    </div>
                </div>
                <div class="report-container">
                    <div class="report-info">
                        <div class="info-choose">
              <span
                      :class="['info-r', tab_index == 0 ? 'reCheck' : '']"
                      @click="clkTab(0)"
              >测试报告</span
              >
                        </div>
                        <div class="info-content" v-if="tab_index == 0">
                            <div class="content-box">
                                <div class="box-tips">
                  <span
                  >学习报告展示了你测试的内容、测试时间以及测试成绩等数据。</span
                  >
                                </div>
                                <div class="box-table">
                                    <el-table
                                            border
                                            max-height="800px"
                                            :header-cell-style="{
                      textAlign: 'center',
                      background: '#fdf6ea',
                      paddingTop: '0',
                    }"
                                            :cell-style="{
                      fontSize: '12px',
                      textAlign: 'center',
                      fontWeight: '400',
                      cursor: 'pointer',
                    }"
                                            :data="tableData"
                                            style="width: 100%"
                                    >
                                        <el-table-column label="序号" width="50" prop="id">
                                        </el-table-column>
                                        <el-table-column prop="shijuan_set.title" label="考试名称">
                                        </el-table-column>
                                        <el-table-column
                                                prop="shijuan.title"
                                                label="试卷名称"
                                        >
                                        </el-table-column>

                                        <el-table-column
                                                prop="created_at"
                                                label="测试时间"
                                                width="160"
                                        ></el-table-column>
                                        <el-table-column
                                                prop="yongshi_time"
                                                label="测试耗时"
                                                width="100"
                                        ></el-table-column>
                                        <el-table-column
                                                prop="score"
                                                label="分数"
                                                width="100"
                                        ></el-table-column>
                                    </el-table>
                                    <div class="footer-page">
                                        <div class="p-info">
                                            第<span class="p-num">{{ page }}</span
                                        >/ <span class="p-total">{{ allPage }}</span
                                        >页，共 <span class="p-data">{{ total }}</span
                                        >条数据
                                        </div>
                                        <el-pagination
                                                background
                                                layout="prev, pager, next"
                                                @current-change="handleCurrentChange"
                                                :total="total"
                                                :hide-on-single-page="false"
                                                :page-size="pageSize"
                                                :current-page="page"
                                        >
                                        </el-pagination>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import {mapState} from "vuex";

export default {
    name: 'PaperReport',
    data() {
        return {
            inside: true,
            percentage: 22,
            prWidth: 150,
            tab_index: 0,
            infoList: [
                {title: "智能记忆", times: "1"},
                {title: "智能词听", times: "1"},
                {title: "智能听写", times: "1"},
                {title: "智能默写", times: "1"},
                // { title: "例句听组", times: "1" },
                // { title: "例句听力", times: "1" },
                // { title: "例句翻译", times: "1" },
                // { title: "例句默写", times: "1" },
            ],
            tableData: [],
            tempoData: [],
            page: 1,
            pageSize: 10,
            total: 0,
            options: [
                {
                    value: "选项1",
                    label: "外研社JoinIn2013（三年级起点）",
                },
                {
                    value: "选项2",
                    label: "【心铭远】外研版课标词汇七年级（上）",
                },
                {
                    value: "选项3",
                    label: "蚵仔煎",
                },
                {
                    value: "选项4",
                    label: "龙须面",
                },
                {
                    value: "选项5",
                    label: "北京烤鸭",
                },
            ],
            value: "",
        };
    },
    updated() {
        let popper = document.querySelector("body>.el-popper");
        console.log(popper, 11);
    },
    computed: {
        ...mapState({
            token: (state) => state.user.token,
            user: (state) => state.user.user,
        }),
        allPage() {
            return Math.ceil(this.total / this.pageSize);
        },
    },
    created() {
        this.getReport();
        //this.tempoReport();
    },
    methods: {
        getReport() {
            //学习报告
            let than = this;
            this.loading = true;
            let params = {
                token: this.token,
                page: this.page,
                pageSize: this.pageSize,
            };
            this.tableData = [];
            this.axios
                    .post("/api/exam/paper_report", params)
                    .then((res) => {
                        console.log(res);
                        if (res.data.status_code == 200) {
                            this.tableData = res.data.data.list;
                            this.total = res.data.data.total;
                            console.log("total:",this.total);
                        } else if (res.data.status_code == 202) {
                        } else {
                            this.$message(res.data.msg);
                        }
                    })
                    .catch((err) => {
                        this.loading = false;
                    });
        },
        tempoReport() {
            //进度报告
            let than = this;
            this.loading = true;
            let params = {
                token: this.token,
                page: this.page,
                pageSize: this.pageSize,
            };
            this.tempoData = [];
            this.axios
                    .post("/api/test_center/study_report_jindu", params)
                    .then((res) => {
                        console.log(res);
                        if (res.data.status_code == 200) {
                            this.tempoData = res.data.data.lists;
                            console.log("tempoData", this.tempoData);
                        } else if (res.data.status_code == 202) {
                        } else {
                            this.$message(res.data.msg);
                        }
                    })
                    .catch((err) => {
                        this.loading = false;
                    });
        },
        clkTab(index) {
            this.tab_index = index;
        },
        handleCurrentChange(page) {
            this.page = page;
            this.getReport();
        },
        gotoXuexi() {
            this.$router.push({name: "papers"});
        },
    },
};
</script>

<style lang="scss" scoped>
@import "~@/assets/css/paper_report.scss";
</style>
